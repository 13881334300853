<template>
  <b-row class="justify-content-center">
    <b-col cols="12" md="6">
      <vx-table-simple
        :items="withdrawalItems"
        :columns="withdrawalColumns"
        stacked
      >
        <template #title>
          Withdrawal
        </template>
      </vx-table-simple>
    </b-col>
    <b-col v-if="purchaseItems" cols="12" md="6">
      <vx-table-simple
        :items="purchaseItems"
        :columns="purchaseColumns"
        stacked
      >
        <template #title>
          Purchase
        </template>
      </vx-table-simple>
    </b-col>
  </b-row>
</template>

<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import { BCol, BRow } from 'bootstrap-vue'
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import {
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellDrop,
  VxCellLink,
  VxCellMoney,
  VxCellJsonViewer, VxCellBadges
} from '@/components/table'
import { buildRoute, path } from '@/router'
import { botStatuses, markets, useResource } from '@/services/resources'

export default {
  name: 'Withdrawal',
  components: {
    VxTableSimple,
    VxCellPlayerAvatar,
    VxCellDate,
    VxCellDrop,
    VxCellLink,
    VxCellMoney,
    VxCellBadges,
    VxCellJsonViewer,
    BCol,
    BRow
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {
    if (!dataResolverResponse) return

    const {
      drop,
      status,
      steam_trade_id,
      error_code,
      created_at,
      updated_at,
      details,
      purchase
    } = dataResolverResponse

    const withdrawalItems = [{
      player: drop.player,
      drop,
      status,
      steam_trade_id,
      error_code,
      created_at,
      updated_at,
      details
    }]
    const purchaseItems = purchase ? [purchase] : purchase

    const marketsList = ref(null)

    const getMarkets = async () => {
      const { can, callRequest } = useResource(markets.getAll)
      if (!can) return null

      const [err, res] = await callRequest({ params: null })
      if (err) return null

      marketsList.value = res.data
    }

    onBeforeMount(() => getMarkets())

    const withdrawalColumns = [
      { key: 'drop', component: VxCellDrop },
      { key: 'player', component: VxCellPlayerAvatar },
      {
        key: 'status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            delivered: 'success',
            error: 'danger'
          }
        }
      },
      { key: 'error_code' },
      { key: 'created_at', component: VxCellDate },
      { key: 'updated_at', component: VxCellDate },
      { key: 'details', component: VxCellJsonViewer }
    ]

    const purchaseColumns = [
      {
        key: 'bot_id',
        label: 'Bot',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.bots, { query: { id } })
        })
      },
      {
        key: 'status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            delivered: 'success',
            failed: 'danger'
          }
        }
      },
      { key: 'main_price', component: VxCellMoney },
      { key: 'market_price', component: VxCellMoney },
      { key: 'class_id' },
      { key: 'instance_id' },
      { key: 'external_id' },
      { key: 'created_at', component: VxCellDate },
      { key: 'updated_at', component: VxCellDate },
      {
        key: 'market_id',
        label: 'Market',
        formatter: (value) => {
          if (!marketsList.value) return '-'
          return marketsList.value.find(({ id }) => id === value).title
        }
      }
    ]

    return {
      withdrawalItems,
      withdrawalColumns,

      purchaseItems,
      purchaseColumns
    }
  }
}
</script>
